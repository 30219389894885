export default class BackArrow {

  // morph straight line path to this swiggly "compressed line" path
  //compressedLinePath = 'M1,2.5h1.9c0,0,0.1-1.9,0.6-2c0.7,0.4,0.8,4,1.6,4c0.9,0,0.9-4,1.7-4c0.9,0,0.9,4,1.7,4c0.9,0,0.9-4,1.7-4c0.9,0,0.9,4,1.7,4c0.9,0,0.9-4,1.7-4c0.9,0,0.9,4,1.7,4c0.9,0,0.9-4,1.7-4c0.9,0,0.9,4,1.7,4c0.9,0,0.9-4,1.7-4s0.9,4,1.7,4';
  //compressedLinePath = 'M1,2.5h1.9l0.6-1l1.6,2l1.7-2l1.7,2l1.7-2l1.7,2l1.7-2l1.7,2l1.7-2l1.7,2l1.7-2l1.7,2';
  //compressedLinePath = 'M2.4,3.3l0.5-0.8l0.6-1l1.6,2l1.7-2l1.7,2l1.7-2l1.7,2l1.7-2l1.7,2l1.7-2l1.7,2l1.7-2l1.7,2';
  //compressedLinePath = 'M2.8,2.7l0.1-0.2l0.6-1l1.6,2l1.7-2l1.7,2l1.7-2l1.7,2l1.7-2l1.7,2l1.7-2l1.7,2l1.7-2l2.1,2.4';
  //compressedLinePath = 'M2.4,2.1L2.4,2.1l1.1-0.6l1.6,2l1.7-2l1.7,2l1.7-2l1.7,2l1.7-2l1.7,2l1.7-2l1.7,2l1.7-2l2.1,2.4';
  //compressedLinePath = 'M2.1,2.7L2.1,2.7l1.4-1.2l1.6,2l1.7-2l1.7,2l1.7-2l1.7,2l1.7-2l1.7,2l1.7-2l1.7,2l1.7-2l2.1,2.4';
  compressedLinePath = 'M2.6,2.9L2.6,2.9l0.9-1.4l1.6,2l1.7-2l1.7,2l1.7-2l1.7,2l1.7-2l1.7,2l1.7-2l1.7,2l1.7-2l2.1,2.4';

  animationTime = 600;

  constructor() {
    this.arrowLineSelector = '.back-arrow__arrow__line';
    this.arrowLineActiveSelector = 'back-arrow__arrow__line--active';
    this.arrowWrapperSelector = '.back-arrow__wrapper';
    this.arrowButtonSelector = '.back-arrow__action';

    this.setupComponent();
  }

  setupComponent() {
    this.arrowLineEl = document.querySelector(this.arrowWrapperSelector);

    var lineSvg = Snap(this.arrowLineSelector);

    if (!lineSvg) {
      return;
    }

    const linePath = lineSvg.select('path');
    const origPathData = linePath.attr('d');

    this.arrowLineEl.addEventListener('mouseover', () => {
      this.mouseOver(lineSvg, linePath, origPathData);
    });

    this.arrowLineEl.addEventListener('mouseout', () => {
      this.mouseOut(lineSvg, linePath, origPathData);
    });
  }

  mouseOver(lineSvg, linePath, origPathData) {
    if (!this.isActive()) {
      return;
    }

    linePath.animate({
      d: this.compressedLinePath,
    }, this.animationTime, mina.bounce);

    this.arrowLineEl.classList.add(this.arrowLineActiveSelector);
  }

  mouseOut(lineSvg, linePath, origPathData) {
    if (!this.isActive()) {
      return;
    }

    linePath.animate({
      d: origPathData
    }, this.animationTime, mina.bounce);

    this.arrowLineEl.classList.remove(this.arrowLineActiveSelector);
  }

  isActive() {
    if (window.innerWidth < 768) {
      return false;
    }

    // Ignore animation control
    return true; //this._animationController.isAnimationsActive();
  }

}