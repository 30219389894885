import Emitter from 'es6-event-emitter';
import { ANIMATION_ACTIVE_CHANGE } from '../../../../Scripts/Handlers/AnimationHandler';

export default class Lesson extends Emitter {

  constructor(stateHandler) {
    super();

    this.container = document.querySelector('.lesson');

    if (!this.container) {
      return;
    }

    this.sectionSelector = '.lesson__content__section';
    this.sections = this.container.querySelectorAll(this.sectionSelector);
    this.paginatorElement = this.container.querySelector('.lesson__content__paginator');
    this.labelElement = this.container.querySelector('.lesson-section-label');

    this.paginatorPageSelector = '.lesson-paginator__list__page';
    this.paginatorPageActiveClass = 'lesson-paginator__list__page--active';
    this.paginatorLinkSelector = '.lesson-paginator__list__page__link';
    this.headerSelector = '.lesson__header';
    this.contentSelector = '.lesson__content';
    this.contentInViewClass = 'lesson__content--in-view';
    this.startButtonSelector = '.lesson__header__content__start';
    this.hasNextClass = 'lesson--has-next';
    this.hasPreviousClass = 'lesson--has-previous';
    this.labelAttrName = 'data-lesson-section-label';
    this.nextButtonSelector = '.lesson__buttons__next';
    this.previousButtonSelector = '.lesson__buttons__previous';
    this.illustrationClass = 'lesson-section__illustration';
    this.illustrationSelector = '.' + this.illustrationClass;

    this.lessonCookieName = 'minarattigheter_latest_lesson_section';
    this.scrollSpeed = 500;
    this.desktopBreakpointPx = 1024;
    this.defaultEyeRotation = -90;
    this.svgEyeOffsets = [45, 45, 45, 45, 45, 45, 45, 45, 45, 45];
    this.svgSelectors = [
      '#lektion-1 svg',
      '#lektion-2 svg',
      '#lektion-3 svg',
      '#lektion-4 svg',
      '#lektion-5 svg'
    ];

    this.svgEyeSelectors = [
      'svg circle.SVG_Illusar-011',
      'svg circle.SVG_Illusar-022',
      'svg circle.SVG_Illusar-034',
      'svg ellipse.SVG_Illusar-041',
      'svg ellipse.SVG_Illusar-052',
    ];

    this.hasMouse = true;
    this.setupEvents();
    this.renderIllustrations();
    this.update();

    setTimeout(this.rotateEyesToDefault.bind(this, this.defaultEyeRotation), 100);

    stateHandler.on(ANIMATION_ACTIVE_CHANGE, (isActive) => {
      this._isActive = isActive;
      setTimeout(this.rotateEyesToDefault.bind(this, this.defaultEyeRotation), 100);
    })
  }

  /**
   * Converts radians to degrees
   * @param {int} radians 
   */
  toDegrees(radians) {
    return radians * (180 / Math.PI);
  }

  /**
   * Calculates the angle between two points
   * @param {int} x 
   * @param {int} y 
   * @param {int} x2 
   * @param {int} y2 
   */
  getAngle(x, y, x2, y2) {
    const angle = this.toDegrees(Math.atan2(y - y2, x - x2));

    return angle;
  }

  rotateEyesToDefault(degree) {
    this.svgEyeSelectors.forEach(svgEyeSelector => {
      const eyes = document.querySelectorAll(svgEyeSelector);

      if (eyes.length < 0) {
        return;
      }

      eyes.forEach(eye => {
        const svg = eye.closest('svg');

        if (svg.length < 0) {
          return;
        }

        this._rotateEyes(eye, degree);
      });
    });
  }

  rotateEyes(ev) {
    if (!this._isActive) {
      this.rotateEyesToDefault(this.defaultEyeRotation);

      return;
    }

    const scrollLeft = window.scrollX;
    const scrollTop = window.scrollY;

    this.svgEyeSelectors.forEach(svgEyeSelector => {
      const eyes = document.querySelectorAll(svgEyeSelector);

      if (eyes.length < 0) {
        return;
      }

      eyes.forEach((eye, index) => {
        const svg = eye.closest('svg');
        const svgOffset = this.getOffset(svg);
        const svgHeight = svg.clientHeight;
        const svgWidth = svg.clientWidth;

        const svgCenterX = (svgOffset.left + (svgWidth / 2)) - scrollLeft;
        const svgCenterY = (svgOffset.top + (svgHeight / 2)) - scrollTop;
        const degree = this.getAngle(ev.clientX, ev.clientY, svgCenterX, svgCenterY) - this.svgEyeOffsets[index];

        this._rotateEyes(eye, degree);
      });
    });
  }

  _rotateEyes(el, degree) {
    const radiusX = el.getAttribute('r') || el.getAttribute('rx');
    const radiusY = el.getAttribute('r') || el.getAttribute('ry');
    const originalOffsetX = Math.round(el.getAttribute('cx') - radiusX);
    const originalOffsetY = Math.round(el.getAttribute('cy') - radiusY);

    el.setAttribute('style', 'transform-origin: ' + originalOffsetX + 'px ' + originalOffsetY + 'px; transform: rotate(' + degree + 'deg);');
  }

  renderIllustrations() {
    const illustrations = document.querySelectorAll(this.illustrationSelector);
    
    illustrations.forEach((ele) => {
      const parent = ele.parentNode;
      const src = ele.getAttribute('src');
      
      if (src.indexOf('.svg') !== -1) {
        fetch(src)
          .then(response => response.text())
          .then(data => {
            parent.innerHTML = data;
            const svg = parent.querySelector('svg');
            svg.classList.add(this.illustrationClass);
          });
      }
    });
  }

  getOffset(element) {
    if (!element.getClientRects().length) {
      return { top: 0, left: 0 };
    }

    let rect = element.getBoundingClientRect();
    let win = element.ownerDocument.defaultView;

    return (
      {
        top: rect.top + win.pageYOffset,
        left: rect.left + win.pageXOffset
      });
  }

  getElementBox(element) {
    const offset = this.getOffset(element);
    const topOfElement = offset.top;
    const elementHeight = element.offsetHeight;
    const bottomOfElement = topOfElement + elementHeight;

    return {
      top: topOfElement,
      bottom: bottomOfElement,
      height: elementHeight
    }
  }

  getViewBox() {
    const topOfScreen = window.scrollY;
    const viewHeight = window.innerHeight;
    const bottomOfScreen = topOfScreen + viewHeight;

    return {
      top: topOfScreen,
      bottom: bottomOfScreen,
      height: viewHeight
    }
  }

  isElementInView(element) {
    const viewbox = this.getViewBox();
    const elementBox = this.getElementBox(element);

    if ((viewbox.bottom > elementBox.top) && (viewbox.top < elementBox.bottom)) {
      return true;
    }

    return false;
  }

  isElementVisible(element, index) {
    if (!this.isElementInView(element)) {
      return false;
    }

    const viewbox = this.getViewBox();
    const elementBox = this.getElementBox(element);
    const multiplier = 0.4; // 0.4 instead of 0.5 because the element box has a marign-top 10vh (10% of view height) which needs to be taken into account
    
    if (((viewbox.bottom) > elementBox.top + (viewbox.height * multiplier))) {
      return true;
    }

    return false;
  }

  isDesktop() {
    const mq = window.matchMedia('(min-width: ' + this.desktopBreakpointPx + 'px)');

    return mq.matches ? true : false;
  }

  setupEvents() {
    window.addEventListener('unload', this._pageDestruct.bind(this));
    window.addEventListener('load', () => {
      this._pageLoad(this.getStoredPagePosition());
    });

    this.paginatorElement.addEventListener('click', this.paginatorClick.bind(this));
    document.querySelector(this.startButtonSelector).addEventListener('click', this.scrollToFirstSection.bind(this));
    document.querySelector(this.nextButtonSelector).addEventListener('click', this.scrollToNextPage.bind(this));
    document.querySelector(this.previousButtonSelector).addEventListener('click', this.scrollToPreviousPage.bind(this));
    window.addEventListener('scroll', this.handleScroll.bind(this));
    window.addEventListener('scroll', this.debounceHandleScroll.bind(this));
    window.addEventListener('resize', this.handleResize.bind(this));
    
    document.querySelector('body').addEventListener('mousemove', this.handleMousemove.bind(this));

    window.addEventListener('mousemove.hasMouse', () => {
      window.removeEventListener('.hasMouse');
      this.hasMouse = true;
    });

    window.addEventListener('touchstart.hasMouse', () => {
      window.removeEventListener('.hasMouse');
      this.hasMouse = false;
    });
  }

  _pageLoad(state, ev) {
    setTimeout(this.restorePagePosition.bind(this, state), 10);
  }

  _pageDestruct(ev) {
    this.storePagePosition();
  }

  restorePagePosition(optState) {
    const state = optState || this.getStoredPagePosition();
    const path = window.location.pathname;
    let previousPosition = null;

    state.map(function (item) {
      if (item.path === path) {
        previousPosition = item;
      }
    });

    if (previousPosition && previousPosition.section) {
      this.scrollTo('#' + previousPosition.section, null, true);
    }
  }

  getStoredPagePosition() {
    const state = Cookies.getJSON(this.lessonCookieName) || [];

    return state;
  }

  setStoredPagePosition(state) {
    Cookies.set(this.lessonCookieName, state || []);
  }

  storePagePosition() {
    const currentSection = this.getCurrentSection();

    if (!currentSection) {
      return;
    }

    const currentPage = currentSection.getAttribute('id');

    let state = this.getStoredPagePosition();

    let replaced = false;
    const current = {
      path: window.location.pathname,
      section: currentPage
    };

    state = state.map((item, i) => {
      if (item.path === current.path) {
        replaced = true;

        return current;
      }

      return item;
    });

    if (!replaced) {
      state.push(current);
    }

    this.setStoredPagePosition(state);
  }

  scrollToNextPage(ev) {
    const currentPage = this.getCurrentSection();
    const nextPage = currentPage.nextElementSibling;

    if (nextPage) {
      this.scrollTo('#' + nextPage.getAttribute('id'));
    } else {
      this.scrollTo('#' + sections[0].getAttribute('id'));
    }

    ev.preventDefault();
  }

  scrollToPreviousPage(ev) {
    const currentPage = this.getCurrentSection();
    const previousPage = currentPage.previousElementSibling;

    if (previousPage) {
      this.scrollTo('#' + previousPage.getAttribute('id'));
    } else {
      this.scrollTo(false, 0);
    }

    ev.preventDefault();
  }

  handleMousemove(ev) {
    this.rotateEyes(ev);
  }

  handleResize(ev) {
    if (!this.isDesktop()) {
      this.resetIllustrationsPositions();
    } else {
      this.positionIllustrations();
    }
  }

  debounceHandleScroll(ev) {
    clearInterval(this._scrollDebounceHandler);

    this._scrollDebounceHandler = setTimeout(
      function (ev) {
        this.storePagePosition();
      }.bind(this, ev),
      50
    )
  }

  handleScroll(ev) {
    this.update();
    this.positionIllustrations();

    if (this.hasMouse == false && this._isActive) {
      const scrollPercent = 100 * window.scrollY / (document.body.clientHeight - window.innerHeight);
      let offset = 45;

      if (window.innerWidth < 768) {
        offset = 300;
      }

      this.rotateEyesToDefault((Math.sin(scrollPercent) * 90) - offset);
    }
  }

  resetIllustrationsPositions(ev) {
    const illustrations = document.querySelectorAll(this.illustrationSelector);

    illustrations.forEach((illustration) => {
      illustration.removeAttribute('style');
    });
  }

  positionIllustrations(ev) {
    if (!this.isDesktop() || !this._isActive) {
      return;
    }

    const illustrations = document.querySelectorAll(this.illustrationSelector);

    illustrations.forEach((illustration) => {
      let isInView = this.isElementInView(illustration);
      let parentOffset;
      let scrollTop;
      let position;
      let parent;
      let parentHeight;
      let parentWidth;

      // Don't update if not in view
      if (!isInView) {
        return;
      }

      parent = illustration.parentElement;

      parentOffset = parent.getBoundingClientRect();
      parentHeight = parent.offsetHeight;
      parentWidth = parent.offsetWidth;

      const illustrationHeight = illustration.offsetHeight;
      const centerOffset = (window.innerHeight / 2) - (illustrationHeight / 2);
      const maxTop = parentHeight - illustrationHeight;

      // Column is smaller than image, nothing to animate
      if (maxTop < 0) {
        return;
      }

      scrollTop = window.scrollY;
      position = scrollTop - parentOffset.top;
      position = position + centerOffset;
      position = position < 0 ? 0 : position;
      position = position > maxTop ? maxTop : position;

      if (position > 0 && position < maxTop) {
        illustration.style.position = 'fixed';
        illustration.style.top = centerOffset + 'px';
        illustration.style.width = parentWidth + 'px';
      } else {
        illustration.style.position = 'absolute';
        illustration.style.top = position + 'px';
      }
    });
  }

  getCurrentSection(ev) {
    const sections = Array.from(this.sections);
    const currentSection = sections.filter(function (ele, i) {
      return this.isElementVisible(ele, i);
    }.bind(this)).slice(-1)[0];

    return currentSection;
  }

  setButtonClasses(currentSection) {
    const header = document.querySelector(this.headerSelector);

    if (this.sections[this.sections.length - 1] == currentSection || !currentSection) {
      this.container.classList.remove(this.hasNextClass);
    } else {
      this.container.classList.add(this.hasNextClass);
    }

    if (this.isElementInView(header)) {
      this.container.classList.remove(this.hasPreviousClass);
    } else {
      this.container.classList.add(this.hasPreviousClass);
    }
  }

  update() {
    const header = document.querySelector(this.headerSelector);
    const content = document.querySelector(this.contentSelector);
    const currentSection = this.getCurrentSection();

    if (!currentSection) {
      return;
    }

    this.setButtonClasses(currentSection);

    if (this.isElementInView(content) && !this.isElementInView(header)) {
      content.classList.add(this.contentInViewClass);
    } else {
      content.classList.remove(this.contentInViewClass);
    }

    this.updateLabel(currentSection);
    this.updatePaginator(currentSection);
  }

  updateLabel(element) {
    const label = element.getAttribute(this.labelAttrName);
    this.labelElement.innerHTML = label;
  }

  updatePaginator(element) {
    const id = element.getAttribute('id');
    const pages = this.paginatorElement.querySelectorAll(this.paginatorPageSelector);

    pages.forEach((page) => {
      const link = page.querySelector(this.paginatorLinkSelector);

      if (link.getAttribute('href').replace('#', '') == id) {
        page.classList.add(this.paginatorPageActiveClass);
      } else {
        page.classList.remove(this.paginatorPageActiveClass);
      }
    });
  }

  scrollTo(eleId, position, noAnimate) {
    const ele = document.querySelector(eleId);
    const scrollPosition = ele ? this.getOffset(ele).top : position;

    if (!noAnimate) {
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
      });
    } else {
      window.scrollTo(0, scrollPosition);
    }
  }

  paginatorClick(ev) {
    const eleId = ev.target.getAttribute('href');
    ev.preventDefault();

    this.scrollTo(eleId);
  }

  scrollToFirstSection(ev) {
    ev.preventDefault();
    const section = document.querySelector(this.sectionSelector + '[id]');
    const id = section.getAttribute('id');
    this.scrollTo('#' + id);
  }
}