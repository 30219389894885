
export default class FlipTeaser {

  constructor() {
    this.flipTeaserSelector = '.flip-teaser';
    this.checkboxSelector = '.flip-teaser__flip-state';
    this.init();
  }

  init() {
    document.addEventListener('DOMContentLoaded', () => {
      this.setupComponent();
    });
  }

  setupComponent() {
    const elements = document.querySelectorAll(this.flipTeaserSelector);

    if (elements.length === 0) {
      return;
    }

    for (let teaser of elements) {
      const frontside = teaser.querySelector('.flip-teaser__frontside');
      const backside = teaser.querySelector('.flip-teaser__backside');
      const button = frontside.querySelector('.flip-teaser__flip-state-label');

      button?.addEventListener('keypress', this.pressedKey.bind(this));

      let heights = [
        frontside.offsetHeight,
        backside.offsetHeight
      ];

      let newHeight = Math.max(...heights);

      frontside.style.height = newHeight + 'px';
      backside.style.height = newHeight + 'px';
    }
  }

  pressedKey(event) {
    const key = event.code;
    const target = event.currentTarget;

    if (key === 'Enter' || key === 'Space') {
      event.preventDefault();
      event.stopPropagation();
      this.flipTeaser(target);
    }
  }

  flipTeaser(target) {
    const teaser = target.closest(this.flipTeaserSelector);
    const checkbox = teaser.querySelector(this.checkboxSelector);
    checkbox.checked = true;
  }

}