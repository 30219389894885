﻿import { tns } from 'tiny-slider';
import 'tiny-slider/dist/tiny-slider.css';

export default class Slider {

  constructor() {
    this.containerSelector = '.carousel__slider';
    this.navContainerSelector = '.carousel__thumbs';
    this.controlsContainerSelector = '.carousel__nav';
    this.slider = {};

    this.init();
  }

  init() {
    document.addEventListener('DOMContentLoaded', () => {
      this.setupComponent();
    });
  }

  setupComponent() {
    let container = document.querySelector(this.containerSelector);

    if (container === null) {
      return;
    }

    this.slider = tns({
      container: this.containerSelector,
      navContainer: this.navContainerSelector,
      controlsContainer: this.controlsContainerSelector,
      autoplay: false,
      center: true,
      speed: 400,
      items: 1,
      loop: true,
      controls: false,
      navAsThumbnails: true,
      slideBy: 'page',
    });
    this.addEventListeners();
    this.setupTabIndex();
  }

  addEventListeners() {
    let prev = document.querySelector('.carousel__prev'),
      next = document.querySelector('.carousel__next');

    prev.addEventListener('click', () => {
      this.slider.goTo('prev');
    });

    next.addEventListener('click', () => {
      this.slider.goTo('next');
    });

    document.addEventListener('keydown', (event) => {
      if (event.key === 'ArrowLeft') {
        this.slider.goTo('prev');
      }
      if (event.key === 'ArrowRight') {
        this.slider.goTo('next');
      }
    });
  }

  setupTabIndex() {
    let i = 1, j = 3;

    let nav = document.querySelectorAll('.carousel__nav > button');
    nav.forEach(function (div) {
      div.setAttribute('tabindex', i++);
    });

    let thumbs = document.querySelectorAll('.thumb-item');
    thumbs.forEach(function (item) {
      item.setAttribute('tabindex', j++);
    });
  }

}