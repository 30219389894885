import axios from 'axios';

export default class VideoList {

  constructor() {
    this.isLoading = false;
    this.setupComponent();
    this.setupEvents();
  }

  setupComponent() {

    this.videoList = document.querySelector('.video-list');
    
    if (!this.videoList) {
      return;
    }

    this.videoListContent = document.querySelector('.video-list__content');
    this.loadMoreButton = this.videoList.querySelector('.js--load-more');

  }

  isValid(data) {

    if (typeof data !== 'string') {
      return false;
    }

    const hasLoadMore = data.match('load-more-container');
    const isHtml = data.match(/<html/i);

    return isHtml === null &&
      hasLoadMore === null;
  }

  setupEvents() {

    if (this.loadMoreButton) {
      this.loadMoreButton.addEventListener('click', this.handleClick.bind(this));
    }
  }

  handleClick(e) {
    e.preventDefault();

    const el = e.currentTarget;
    const url = el.getAttribute('data-load-url');

    this.fetchItems(url);
  }

  fetchItems(url) {

    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    axios.get(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    }).then(this.handleResponse.bind(this));
  }

  handleResponse(data) {

    this.isLoading = false;

    if (data) {
      if (this.isValid(data.data.items.result)) {
        this.videoListContent.innerHTML = this.videoListContent.innerHTML + data.data.items.result;
        const firstItemInNewList = this.videoListContent.querySelector("ul:last-of-type li:first-of-type a");
        if (firstItemInNewList) {
          firstItemInNewList.focus();
        }  
      } else {
        console.error('[VideoList] Malformed response');
      }
      if (data.data.loadMoreUrl) {
        this.loadMoreButton.setAttribute('data-load-url', data.data.loadMoreUrl);
      } else {
        this.loadMoreButton.classList.add('hide-button');
      }

    } else {
      console.error('[VideoList] Malformed response');
    }
  }
}