import axios from 'axios';

export default class TeaserLoader {

  constructor() {
    this.loadTriggerSelector = '.js--load-lessons';
    this.loadUrlAttr = 'data-load-url';
    this.targetAttr = 'data-load-target';
    this.teaserInitClass = 'lesson-teaser--invisible';
    this.teaserSelector = '.lesson-teaser';
    this.loadedId = [];

    this.setupEvents();
  }

  setupEvents() {
    
    let button = document.querySelector(this.loadTriggerSelector);

    if (!button) {
      return;
    }
    
    button.addEventListener('click', this.handleClick.bind(this));
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }

  isElementInView(element) {
    const elRect = element.getBoundingClientRect();
    const elTop = elRect.top;
    const elBottom = elRect.bottom;
    const screenBottom = window.innerHeight;
    const screenTop = 0;

    if ((screenBottom > elTop) && (screenTop < elBottom)) {
      return true;
    }

    return false;
  }

  handleScroll(ev) {
    let elements = document.querySelectorAll('.' + this.teaserInitClass);
    
    for (let element of elements) {
      if (this.isElementInView(element)) {
        element.classList.remove(this.teaserInitClass);
      }
    }
  }

  handleClick(ev) {
    const loadDivider = ev.currentTarget;
    const target = document.querySelector(`[${this.targetAttr}]`);
    const url = loadDivider.getAttribute(this.loadUrlAttr);

    // Store all teaser ids that are currently being displayed
    this.loadedId = Array.from(document.querySelectorAll(this.teaserSelector)).map(ele => ele.id);

    axios.get(url)
      .then(response => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(response.data, 'text/html');

        // Filter out elements that haven't been displayed
        const elements = Array.from(doc.querySelectorAll(this.teaserSelector)).filter(ele => !this.loadedId.includes(ele.id));

        const newLoadDivider = doc.querySelector(this.loadTriggerSelector);

        if (newLoadDivider) {
          const newUrl = newLoadDivider.getAttribute(this.loadUrlAttr);
          loadDivider.setAttribute(this.loadUrlAttr, newUrl);
        } else {
          loadDivider.style.display = 'none';
        }

        elements.forEach(ele => ele.classList.add(this.teaserInitClass));
        target.append(...elements);
        elements[0].querySelector('a, button, label').focus()

        this.handleScroll();
      }).bind(this);


    ev.preventDefault();
  }
}