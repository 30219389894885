import Emitter from 'es6-event-emitter';
import { ANIMATION_ACTIVE_CHANGE } from './AnimationHandler';

export default class AnimatedBackgroundImageHandler extends Emitter {

  constructor(stateHandler) {
    super();
    this.containerSelector = '.animated-background-image';
    this.imageSelector = '.animated-background-image__item';
    this.imagePxWidth = 200;
    this.imagePxHeight = 200;
    this.animatedClass = 'animated-background-image__item--animated';
    this.desktopBreakpointPx = '500';

    stateHandler.on(ANIMATION_ACTIVE_CHANGE, (isActive) => {
      this.isActive = isActive;
      this.resize();
    })

    this.setupEvents();
  }

  setupEvents() {
    window.addEventListener('resize', () => this.resize());
    this.resize();
  }

  resize() {
    if (this.shouldAnimate()) {
      this.destructElements();
      this.constructElements();
    } else {
      this.destructElements();
    }
  }

  setNewPosition(width, height) {
    const container = document.querySelector(this.containerSelector);

    const maxX = container.offsetWidth - width;
    const maxY = container.offsetHeight - height;
    const x = Math.floor(Math.random() * maxX);
    const y = Math.floor(Math.random() * maxY);

    return 'translate(' + x + 'px,' + y + 'px)';
  }

  animate(ele) {
    if (!this.shouldAnimate()) {
      return;
    }

    ele.classList.add(this.animatedClass);

    ele.style.transform = this.setNewPosition(ele.offsetWidth, ele.offsetHeight);
  }

  onTransitionEnd(ele) {
    this.animate(ele);
  }

  constructElements() {
    const images = document.querySelectorAll(this.imageSelector);

    images.forEach((ele) => {
      ele.style.transform = this.setNewPosition(ele.offsetWidth, ele.offsetHeight);
      ele.addEventListener('transitionend',
        this.onTransitionEnd.bind(this, ele)
      );
      setTimeout(this.animate.bind(this, ele), 0);
    });
  }

  destructElements() {
    const images = document.querySelectorAll(this.imageSelector);

    images.forEach((ele) => {
      ele.classList.remove(this.animatedClass);
    });
  }

  getRandomArbitrary(min, max) {
    return Math.round(Math.random() * (max - min) + min);
  }

  isDesktop() {
    const mq = window.matchMedia('(min-width: ' + this.desktopBreakpointPx + 'px)');

    return mq.matches ? true : false;
  }

  shouldAnimate() {
    return this.isDesktop() && this.isActive;
  }
}