const _temp0 = require("../../Features/Components/Accordion/Scripts/index.js");
const _temp1 = require("../../Features/Components/BackArrow/Scripts/index.js");
const _temp2 = require("../../Features/Components/Carousel/Scripts/index.js");
const _temp3 = require("../../Features/Components/FlipTeaser/Scripts/index.js");
const _temp4 = require("../../Features/Components/InstructionList/Scripts/index.js");
const _temp5 = require("../../Features/Components/InterpretationAdaptedVideo/Scripts/index.js");
const _temp6 = require("../../Features/Components/Poll/Scripts/index.js");
const _temp7 = require("../../Features/Components/Teaser/Scripts/index.js");
const _temp8 = require("../../Features/Components/ToolTeaserList/Scripts/index.js");
const _temp9 = require("../../Features/Components/VideoList/Scripts/index.js");
const _temp10 = require("../../Features/Components/Youtube/Scripts/index.js");

const _temp11 = require("../../Features/Pages/BobotGui/Scripts/index.js");
const _temp12 = require("../../Features/Pages/LessonFinderPage/Scripts/index.js");
const _temp13 = require("../../Features/Pages/LessonPage/Scripts/index.js");

module.exports = {
  "Components":   {
    "Accordion": _temp0,
    "BackArrow": _temp1,
    "Carousel": _temp2,
    "FlipTeaser": _temp3,
    "InstructionList": _temp4,
    "InterpretationAdaptedVideo": _temp5,
    "Poll": _temp6,
    "Teaser": _temp7,
    "ToolTeaserList": _temp8,
    "VideoList": _temp9,
    "Youtube": _temp10
  },
  "Pages":   {
    "BobotGui": _temp11,
    "LessonFinderPage": _temp12,
    "LessonPage": _temp13
  }
}