import axios from 'axios';
import Emitter from 'es6-event-emitter';

export default class Poll extends Emitter {

  constructor(stateHandler) {
    super();

    this.outerContainer = '.masonry-row';
    this.pollElementSelector = '[data-poll]';
    this.pollElementCheckboxSelector = '.flip-teaser__flip-state';
    this.pollBacksideSelector = '.flip-teaser__backside .flip-teaser__inner__vertical-align';
    this.pollPostUrlAttr = 'data-poll-post-url';
    this.pollCanVoteUrlAttr = 'data-poll-can-vote-url';
    this.pollPostUrlSelector = '[' + this.pollPostUrlAttr + ']';

    this.checkVoteStatus();
    this.setupComponent();

    stateHandler.on('masonry-load-page', () => {
      this.checkVoteStatus();
      this.setupComponent();
    })
  }

  checkVoteStatus() {
    const pollElementSelector = document.querySelectorAll(this.pollElementSelector);

    pollElementSelector.forEach(element => {
      const url = element.getAttribute(this.pollCanVoteUrlAttr);

      if(url, element) {
        const stateCheckbox = element.querySelectorAll(this.pollElementCheckboxSelector);
        axios.get(url)
          .then( response => {        
            if(response.data) {
              stateCheckbox.checked = !response.data;
            } else {
              stateCheckbox.checked = response.data;
            }
        }).bind(this);
      }
    });
  }

  setupComponent() {
    const selectors = document.querySelectorAll(this.pollPostUrlSelector);

    selectors.forEach(selector => {
      this.removeEvents(selector);
      this.setupEvents(selector);
    })
  }

  setupEvents(selector) {
    selector.addEventListener('keypress', this.pressedKey.bind(this));
    selector.addEventListener('click', this.clickedVote.bind(this));
  }

  removeEvents(selector) {
    selector.removeEventListener('keypress', this.pressedKey.bind(this));
    selector.removeEventListener('click', this.clickedVote.bind(this));
  }

  pressedKey(event) {
    const key = event.code;
    const target = event.currentTarget;

    if(key === 'Enter' || key === 'Space') {
      event.preventDefault();
      event.stopPropagation();
      this.vote(target);
    }
  }

  clickedVote(event) {
    const target = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    this.vote(target);
  }

  vote(target) {
    const url = target.getAttribute(this.pollPostUrlAttr);
    const parent = target.closest(this.pollElementSelector);

    axios.get(url).then(response => {
      this.flip(parent, response.data);
    }).bind(this, parent);
  }

  flip(pollContainer, backsideContent) {
    const backside = pollContainer.querySelector(this.pollBacksideSelector);
    const stateCheckbox = pollContainer.querySelector(this.pollElementCheckboxSelector);
    backside.innerHTML = backsideContent;
    stateCheckbox.checked = true;
  }
}