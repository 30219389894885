export default class PopupHandler {

  constructor() {
    this.setup();
    this.keyDownListener = this.keyDownListener.bind(this);
  }

  setup() {

    this.popups = document.querySelectorAll('.popup');
    this.openButtons = document.querySelectorAll('.button--open-popup');
    this.closeButtons = document.querySelectorAll('.js--button--close-popup');

    this.popups.forEach((popup) => {
      popup.classList.add('popup--closed');
    });

    this.openButtons.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.preventDefault();

        const id = e.currentTarget.dataset.popupId;
        this.openPopup(id);
      });
    });

    this.closeButtons.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.preventDefault();

        const id = e.currentTarget.closest('.popup').dataset.id;
        this.closePopup(id);
      });
    });
  }

  // trap focus in modal
  enableTrapFocus() {
    const closeButton = document.querySelector('.js--button--close-popup');
    closeButton.focus(); // add initial focus

    const popup = document.querySelector('.popup'); // select the modal by class
    const focusableElements = 'button, [href], a';
    const focusableContent = popup.querySelectorAll(focusableElements);

    this.firstFocusableElement = focusableContent[0]; // get first element to be focused inside modal
    this.lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

    document.addEventListener('keydown', this.keyDownListener);
  }

  keyDownListener(event) {
    const isTabPressed = event.key === 'Tab' || event.keyCode === 9;

    if (!isTabPressed) {
      return;
    }

    if (event.shiftKey) {
      // if shift key pressed for shift + tab combination
      if (document.activeElement === this.firstFocusableElement) {
        const closeButton = document.querySelector('.js--button--close-popup');
        closeButton.focus(); // add focus for the last focusable element
        event.preventDefault();
      }
    } else {
      // if tab key is pressed
      if (document.activeElement === this.lastFocusableElement) {
        // if focused has reached to last focusable element then focus first focusable element after pressing tab
        const closeButton = document.querySelector('.js--button--close-popup');
        closeButton.focus();
        event.preventDefault();
      }
    }
  }

  disableTrapFocus() {
    const openButton = document.querySelector('.button--open-popup');
    openButton.focus();
    document.removeEventListener('keydown', this.keyDownListener);
  }

  openPopup(id) {
    const popup = document.querySelector(`.popup[data-id="${id}"]`);
    popup.classList.remove('popup--closed');
    popup.classList.add('popup--opened');
    document.documentElement.classList.add('has-popup');
    this.enableTrapFocus();
  }

  closePopup(id) {
    const popup = document.querySelector(`.popup[data-id="${id}"]`);
    popup.classList.remove('popup--opened');
    popup.classList.add('popup--closed');
    document.documentElement.classList.remove('has-popup');
    this.disableTrapFocus();
  }
}