import axios from 'axios';

export default class TeacherTools {

  constructor() {
    this.isLoading = false;
    this.teacherToolsListClass = '.teacher-tools__list';
    this.scope = document.querySelector('.teacher-tools');
    this.setupEvents();
  }

  setupEvents() {
    if (!this.scope) {
      return;
    }

    const loadMore = this.scope.querySelector('.js--load-more');

    if (!loadMore) {
      return;
    }

    loadMore.addEventListener('click', (event) => {
      this.handleClick(event);
    });
  }

  handleClick(e) {
    e.preventDefault();
    const el = e.currentTarget;
    const url = el.getAttribute('data-load-url');

    this.fetchItems(url);
  }

  fetchItems(url) {

    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    axios.get(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    }).then(this.handleResponse.bind(this));
  }

  handleResponse(data) {

    this.isLoading = false;

    if (data) {

      if (this.isValid(data.data.items.result)) {
        const teacherToolsList = this.scope.querySelector(this.teacherToolsListClass);
        teacherToolsList.innerHTML += data.data.items.result;
      } else {
        console.error('[TeacherTools] Malformed response');
      }

      if (data.data.loadMoreUrl) {
        this.scope.querySelector('.js--load-more').getAttribute('data-load-url', data.data.loadMoreUrl);
        this.scope.querySelector('.js--load-more').classList.remove('load-more--hide-button');
      } else {
        this.scope.querySelector('.js--load-more').classList.add('load-more--hide-button');
      }

    } else {
      console.error('[TeacherTools] Malformed response');
    }
  }

  isValid(data) {

    if (typeof data !== 'string') {
      return false;
    }

    const has_load_more = data.match('load-more-container');
    const is_html = data.match(/<html/i);

    return is_html === null &&
      has_load_more === null;
  }
}