
export default class Accordion {

  constructor() {
    this.accordionItemClass = '.accordion__list__item';
    this.accordionItemActiveClass = 'accordion__list__item--open';
    this.accordionItemTitleClass = '.accordion__list__item__title';
    this.accordionPanelClass = '.accordion__list__item__content';
    this.accordionShowPanelClass = 'accordion__list__item__content--show';

    this.setupComponent();
  }

  setupComponent() {
    const accordionItems = document.querySelectorAll(this.accordionItemTitleClass);

    accordionItems.forEach(accordion => {
      this.setupEvents(accordion)
    });
  }

  setupEvents(accordion) {
    accordion.addEventListener('keydown', (ev) => {
      const keyCode = ev.code;
      const target = ev.currentTarget;
      const parent = target.closest(this.accordionItemClass);
      const panel = parent.querySelector(this.accordionPanelClass);

      if (keyCode === 'Enter' || keyCode === 'Space') {
        this.toggle(target, panel, parent);
        return false;
      }
    });

    accordion.addEventListener('click', (ev) => {
      const target = ev.currentTarget;
      const parent = target.closest(this.accordionItemClass);
      const panel = parent.querySelector(this.accordionPanelClass);

      this.toggle(target, panel, parent);

      ev.preventDefault();
    });
  }

  toggle(trigger, panel, parent) {
    const content = parent.querySelector(this.accordionPanelClass);
    const tabbableElements = content.querySelectorAll('a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])');

    if (parent.classList.contains(this.accordionItemActiveClass)) {
      parent.classList.remove(this.accordionItemActiveClass);
      panel.setAttribute('aria-hidden', true);
      trigger.setAttribute('aria-expanded', false);
      content.classList.remove(this.accordionShowPanelClass);     
    } else {
      parent.classList.add(this.accordionItemActiveClass);
      panel.setAttribute('aria-hidden', false);
      trigger.setAttribute('aria-expanded', true);
      content.classList.add(this.accordionShowPanelClass);
    }
  }
}