import Vue from 'vue';
import * as Cookie from 'js-cookie';
import Analytics from '../Analytics';

// --------------------------------------------------------------
// Components
// --------------------------------------------------------------

export default class CookieBarHandler {

  constructor() {
    this.analytics = new Analytics();
    this.onAccept = this.onAccept.bind(this);
    this.acceptedCookieName = '';
    this.defaultCookieGroup = '';
    this.setup();
  }

  setup() {
    const componentClass = '.js--cookiebar';
    const element = document.querySelector(componentClass);

    if (!element) {
      return;
    }

    this.acceptedCookieName = element.attributes['data-cookie-notice-dismissed-name'].value;
    this.defaultCookieGroup = element.attributes['data-default-cookie-group'].value;
    const accepted = Cookie.get(this.acceptedCookieName);

    if (!accepted && element) {
      this._component = new Vue({
        el: element,
        data: () => ({
          visible: true
        }),
        methods: {
          clickAccept: this.onAccept
        }
      });
    }
  }

  onAccept(e) {
    var cookieGroupName = e.target.attributes['data-cookie-allowed-group-name'].value;

    // add default cookie group if not seleceted
    if (cookieGroupName !== this.defaultCookieGroup) {
      cookieGroupName = [this.defaultCookieGroup, cookieGroupName].join(',').replace('"', '');
    }

    e.preventDefault();
    this._component.visible = false;

    Cookie.set(this.acceptedCookieName, cookieGroupName, { expires: 365 });

    if (this.analytics) {
      this.analytics.dispatchAnalyticsEvent(true, 'has_cookie_consent');
    }
  }

}