import Vue from 'vue';
import * as Cookie from 'js-cookie';
import Analytics from '../Analytics';

// --------------------------------------------------------------
// Components
// --------------------------------------------------------------

export default class CookieSettingsHandler {

  constructor() {
    this.analytics = new Analytics();
    this.acceptedCookieName = '';
    this.onStatusChange = this.onStatusChange.bind(this);
    this.setup();
  }

  setup() {
    const componentClass = '.js--cookiesettings';
    const element = document.querySelector(componentClass);

    if (!element) {
      return;
    }

    this.acceptedCookieName = element.attributes['data-cookie-notice-dismissed-name'].value;
    this.defaultCookieGroup = element.attributes['data-default-cookie-group'].value;
    this.setCookieGroups;

    // read back set cookie
    var existingCookie = Cookie.get(this.acceptedCookieName);

    if (existingCookie) {
      this.setCookieGroups = existingCookie.split(",");
    }

    if (element) {
      this._component = new Vue({
        el: element,
        data: () => ({
          checkedGroups: this.setCookieGroups ? this.setCookieGroups : [this.defaultCookieGroup] //default value
        }),
        methods: {
          changeStatusChange: this.onStatusChange
        }
      });
    }
  }

  onStatusChange = function (e) {
    const cookieGroups = this._component.checkedGroups.join(',').replace('"', '');
    Cookie.set(this.acceptedCookieName, cookieGroups, { expires: 365 });

    if (this.analytics) {
      this.analytics.dispatchAnalyticsEvent(true, 'has_ga_cookie_consent');
    }
  }

}