import axios from 'axios';
import Choices from 'choices.js';

export default class LessonFilter {

  constructor() {

    this.lessonContainer = document.querySelector('.lesson-filter');

    if (!this.lessonContainer) {
      return;
    }
    
    this.choices = [];
    this.basePath = '/api/';
    this.apiSubject = 'LessonSubjectFilter';
    this.apiAudience = 'LessonGradeFilter';
    this.apiTheme = 'LessonThemeFilter';
    this.formEl = this.lessonContainer.querySelector('form');
    this.subjectEl = document.getElementById('Subject');
    this.gradeEl = document.getElementById('Grade');
    this.themeEl = document.getElementById('Theme');

    this.selects = this.lessonContainer.querySelectorAll(
      '.lesson-filter__filters__filter__select'
    );

    this.filterAllSelects(); 
    this.setupComponents();
    this.setupAPI();
  }

  /**
   * Setup all dropdown components.
   */
  setupComponents() {
    this.selects.forEach((select) => {
      this.setupComponent(select);
    });
  }

  /**
   * Setup single dropdown component.
   */
  setupComponent(select) {
    const choice = new Choices(select, {
      silent: false, placeholder: true, placeholderValue: "-1", shouldSort: false, renderSelectedChoices: 'auto', allowHTML: false, removeItems: false, itemSelectText: '', searchEnabled: false, searchChoices: false
    });

    select.addEventListener('change', this.onSelect.bind(this));

    this.choices.push(choice);
  }

  /**
  * Setup listeners etc.
  */
  setupAPI() {
    // Get API Path
    this.basePath = this.formEl.getAttribute('data-filter-api');

    if (typeof this.basePath === 'string' &&
      this.basePath.charAt(this.basePath.length - 1) !== '/'
    ) {
      this.basePath = this.basePath + '/';
    } else {
      this.basePath = '/api/';
    }
  }

  /**
   * Run filter on all selects
   */
  filterAllSelects() {
    this.selects.forEach((select) => {
      this.filterByElement(select);
    });
  }

  /**
   * Triggered on select event.
   * @param {Object} e Event data
   */
  onSelect(e) {
    this.filterByElement(e.target);
  }

  /**
   * Run filter based on given elements data-type attribute.
   * @param element
   */
  filterByElement(element) {
    const subjectId = this.subjectEl.value || 0;
    const gradeId = this.gradeEl.value || 0;
    const themeId = this.themeEl.value || 0;

    const type = element.dataset.type;

    //subject changed
    if (type === 'subject') {
      this.fetchFilteredList(
        this.apiAudience, { subjectId: subjectId, themeId: themeId },
        'audience'
      );
      this.fetchFilteredList(
        this.apiTheme, { subjectId: subjectId, gradeId: gradeId },
        'theme'
      );
    }

    //audience changed
    if (type === 'audience') {
      this.fetchFilteredList(
        this.apiSubject, { gradeId: gradeId, themeId: themeId },
        'subject'
      );
      this.fetchFilteredList(
        this.apiTheme, { subjectId: subjectId, gradeId: gradeId },
        'theme'
      );
    }

    //theme changed
    if (type === 'theme') {
      this.fetchFilteredList(
        this.apiAudience, { subjectId: subjectId, themeId: themeId },
        'audience'
      );
      this.fetchFilteredList(
        this.apiSubject, { gradeId: gradeId, themeId: themeId },
        'subject'
      );
    }
  }

  /**
   * Fetches an array of ids that should be kept enabled.
   * @param {Object} e Event
   */
  fetchFilteredList(path, data, type) {
    axios
      .get(this.basePath + path, { params: data })
      .then((response) => {
        const parsed = this.parseResponse(response.data);
        this.filterSelect(type, parsed);
      })
      .catch((error) => {
        this.filterSelect(type, null);
      });
  }

  /**
   * Parses response from API
   * @param {Array} data
   */
  parseResponse(data) {
    return data.map((item) => {
      return '' + item.id;
    });
  }

  /**
   * Filter a select element. Takes a type and an array of strings.
   * To enable all elements set enabled to null, to disable all set it to an empty array.
   *
   * @param {String} type Used to identify the select.
   * @param {Array} enabled A list of id:s to enable, the rest will be disabled
   */
  filterSelect(type, enabled) {
    const choice = this.choices.filter(choice => choice.passedElement.element.dataset.type === type)[0];

    if (!choice) return;

    let prev = choice.getValue(true);
    let options = choice.config.choices;

    options.forEach(option => {
      const value = option.value;

      if (choice.getValue() === value) {
        option.select = true;
      }
      option.disabled = value!== '-1' && (enabled && value && (!enabled.length || !enabled.includes(value)));
    });

    choice.clearChoices();
    choice.setChoices(options);
    choice.setChoiceByValue(prev);
  }
}