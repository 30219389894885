
export default class Teaser {

  constructor() {
    this.init();
  }

  init() {
    document.addEventListener('DOMContentLoaded', this.setup.bind(this));
    document.addEventListener('resize', this.setup.bind(this));
  }

  setup() {
    const breakingNews = document.querySelectorAll('.article-teaser--breaking-news .article-teaser__wrapper');

    if (breakingNews.length === 0) {
      return;
    }

    for (let teaser of breakingNews) {
      teaser.style.height = 'auto';
      let width = teaser.offsetWidth;
      let height = teaser.offsetHeight;

      if (height < width) {
        teaser.style.height = width + 'px';
      }
    }
  }

}