
export default class LessonFinder {

  constructor() {
    this.lessonFinderContainer = document.querySelector('.lesson-finder');

    if (!this.lessonFinderContainer) {
      return;
    }
    
    this.lessonFinderInvalidInputClass = 'lesson-finder--error';
    this.lessonFinderNoInputClass = 'lesson-finder--no-input';
    this.lessonFinderAnimationClass = 'lesson-finder--animation';
    this.defaultTextAttr = 'data-default-text';
    this.validLessonsAttr = 'data-valid-lessons';
    this.errorMessageAttr = 'data-error-message';
    this.errorMessageContainer = this.lessonFinderContainer.querySelectorAll('.js--lesson-finder-error-message');
    this.buttonEl = document.querySelector('.lesson-finder__content__controls__button-container__button');
    this.inputEl = document.querySelectorAll('.lesson-finder__content__controls__input-container__input');
    this.formEl = document.querySelector('form');
    this.errorInvalidInput = 1;
    this.errorEmptyString = 2;
    this.errorLessonDoesntExist = 4;
    this.hasError = false;
    this.lastErrorMessage = '';

    if (window.innerWidth > 768) {
      this.inputEl.forEach(elm => {
        elm.focus();
      });
    }

    this.setupEvents();
    this.trapFocus();
    
    if (this.lessonFinderContainer.getAttribute(this.errorMessageAttr)) {
      this.setErrorState(true, this.lessonFinderContainer.getAttribute(this.errorMessageAttr));
    } else if (this.inputEl.value == '') {
      this.setErrorState(this.errorEmptyString, '');
    }

    // Setup animation class in the next tick (setImmediate hack)
    setTimeout(this.setupAnimations.bind(this), 0);
  }

  setupAnimations() {
    this.lessonFinderContainer.classList.add(this.lessonFinderAnimationClass);
  }

  setupEvents() {
    this.formEl.addEventListener('submit', this.handleSubmit.bind(this));
    this.buttonEl.addEventListener('click', this.handleSubmit.bind(this));

    this.inputEl.forEach(elm => {
      elm.addEventListener('change', (ev) => {
        this.validate(ev.target);
      });

      elm.addEventListener('keyup', (ev) => {
        this.validate(ev.target);
      });
    });
  }

  setErrorState(errorState, message) {
    const changedFromError = (this.hasError === 4 && errorState === 2) || (this.hasError === 4 && errorState === false);

    let errorClass;
    this.hasError = errorState; // Boolean passed from constructor

    this.lessonFinderContainer.classList.remove(this.lessonFinderInvalidInputClass);
    this.lessonFinderContainer.classList.remove(this.lessonFinderNoInputClass);

    if (!this.hasError) {
      this.setErrorMessage(false);

      // Fixes page jump on mobile caused by page height change.
      if (changedFromError) {
        window.scrollTo(0, document.body.scrollHeight);
      }

      return;
    }

    if (errorState & this.errorInvalidInput) {
      errorClass = this.lessonFinderInvalidInputClass;
    }

    if (errorState & this.errorLessonDoesntExist) {
      errorClass = this.lessonFinderInvalidInputClass;
    }

    if (errorState & this.errorEmptyString) {
      errorClass = this.lessonFinderNoInputClass;
    }

    // Passes data-error-message to setErrorMessage
    this.setErrorMessage(message);

    this.lastErrorMessage = message;

    this.lessonFinderContainer.classList.add(errorClass);

    // Fixes page jump on mobile caused by page height change.
    if (changedFromError) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }

  setErrorMessage(message) {
    if (!message) {
      this.errorMessageContainer.forEach(el => {
        const defaultText = el.getAttribute(this.defaultTextAttr);

        el.innerHTML = defaultText || '&nbsp;';

        if (!defaultText) {
          el.classList.remove('visible')
        }
      });

      return;
    }

    this.errorMessageContainer.forEach(el => {
      this.showErrorMessage(el);
      el.innerHTML = message;
    });
  }

  showErrorMessage(el) {
    el.classList.add('visible')
  }

  validate(target) {
    const validLessons = this.lessonFinderContainer.getAttribute(this.validLessonsAttr).split(',');
    let lessonNumber = target.value;

    if (lessonNumber === '') {
      this.setErrorState(this.errorEmptyString, '');
      return;
    }

    if (validLessons.indexOf(lessonNumber) == -1) {
      this.setErrorState(this.errorLessonDoesntExist, 'Lektionen finns inte. Testa ett annat nummer!');
      return;
    }

    this.setErrorState(false, '');
  }

  trapFocus() {
    const element = this.lessonFinderContainer.querySelector('.lesson-finder__content__image__content');
    const focusableElements = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement = focusableElements[focusableElements.length -1];

    element.addEventListener('keydown', e => {
      let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus();
          e.preventDefault();
        }
      } else {
        if(document.activeElement === lastFocusableElement) {
          firstFocusableElement.focus();
          e.preventDefault();
        }
      }
    })
  }

  handleSubmit(ev) {
    if (this.hasError) {
      ev.preventDefault();
      return;
    }
  }
}