import PersistentCheckbox from './PersistentCheckbox.js';
import { CHANGE_STATE } from './StateHandler';

export const ANIMATION_ACTIVE_CHANGE = 'animations-active-change';

export default class AnimationHandler {

  constructor(stateHandler) {
    this.stateHandler = stateHandler;
    this.persistentCheckbox = new PersistentCheckbox();
    this.persistentCheckboxId = 'animation-controller';
    this.animationControllerSelector = '[data-animation-controller]';
    this.isAnimationsActive = false;

    this.updateValue();
    this.setupEvents();
  }

  updateValue(ev) {
    let checkboxValue = this.persistentCheckbox.getCheckedForId(
      this.persistentCheckboxId
    );

    // null == not previously set, should be "on" per default
    if (checkboxValue === null) {
      checkboxValue = true;
    }

    this.isAnimationsActive = checkboxValue;

    if (this.isAnimationsActive) {
      document.body.classList.add('animations-active');
    } else {
      document.body.classList.remove('animations-active');
    }

    this.stateHandler.trigger(ANIMATION_ACTIVE_CHANGE, this.isAnimationsActive);
  }

  setupEvents() {
    const controller = document.querySelector(this.animationControllerSelector);

    if (!controller) {
      return;
    }

    controller.addEventListener(
      'change',() => {
        setTimeout(this.updateValue.bind(this), 10);
      }
    );
  }
}