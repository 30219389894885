import Cookies from 'js-cookie'

export default class PersistentCheckbox {

  constructor() {
    this.checkboxIdAttr = 'data-persistent-checkbox-id';
    this.checkboxSelector = '[' + this.checkboxIdAttr + ']';
    this.buttonInactiveClass = 'button--unchecked';
    this.buttonActiveClass = 'button--checked';
    this.cookieName = 'persistent-checkbox';

    this.reflectState();
    this.setupEvents();
  }

  reflectState() {
    let state = Cookies.getJSON(this.cookieName) || [];

    for (var i = 0; i < state.length; i++) {
      let targets = document.querySelectorAll('[' + this.checkboxIdAttr + '=' + state[i].id + ']');

      targets.forEach((el) => {
        if (state[i].checked === true) {
          el.setAttribute('aria-pressed', 'true');
        } else {
          el.setAttribute('aria-pressed', 'false');
        }

        if (el.tagName === 'INPUT') {
          el.checked = state[i].checked;
        } else if (el.classList.contains('button')) {
          if (state[i].checked) {
            el.classList.remove(this.buttonInactiveClass);
            el.classList.add(this.buttonActiveClass);
          } else {
            el.classList.remove(this.buttonActiveClass);
            el.classList.add(this.buttonInactiveClass);
          }
        }
      });
    }
  }

  setupEvents() {
    document.body.addEventListener('change', (ev) => {
      if (ev.target.matches(this.checkboxSelector)) {
        this.checkboxChange(ev);
      }
    });

    // Make input id, name and label for attributes unique to avoid checkbox bug.
    const checkboxes = document.querySelectorAll(this.checkboxSelector);
    checkboxes.forEach((el, i) => {
      let inputId = el.id;
      let inputName = el.name;
      let nextSibling = el.nextElementSibling;

      inputId = inputId ? inputId : 'persistent-checkbox';
      inputName = inputName ? inputName : 'persistent-checkbox';
      el.id = inputId + '-' + i;
      el.name = inputName + '-' + i;

      if (nextSibling) {
        let labelFor = nextSibling.getAttribute('for');

        labelFor = labelFor ? labelFor : 'persistent-checkbox';
        nextSibling.setAttribute('for', labelFor + '-' + i);
      }
    });
  }

  storeCheckboxState(id, checked) {
    let state = Cookies.getJSON(this.cookieName) || [];
    let item = false;

    for (let i = 0; i < state.length; i++) {
      if (state[i].id == id) {
        item = state[i];
      }
    }

    if (!item) {
      item = {};
      state.push(item);
    }

    item.id = id;
    item.checked = checked;

    Cookies.set(this.cookieName, state);
  }

  getCheckedForId(id) {
    let state = Cookies.getJSON(this.cookieName) || [];

    for (var i = 0; i < state.length; i++) {
      if (state[i].id === id) {
        return state[i].checked;
      }
    }

    return null;
  }

  checkboxChange(ev) {
    const target = ev.target;
    const targetId = target.getAttribute(this.checkboxIdAttr);
    const isChecked = target.checked;

    this.storeCheckboxState(targetId, isChecked);
    this.reflectState();
  }
}