import YouTubeIframeLoader from 'youtube-iframe';

export default class VideoPlayerInterpreted {

  constructor() {
    this.containerSelector = '.video-player-interpreted';
    this.playerSelector = '.video-player-interpreted__videos__player';
    this.playerTriggerButtonClass = 'button--interpret-select';
    this.playerTriggerButton = '.' + this.playerTriggerButtonClass;
    this.disabledButtonClass = '.button--disabled';
    this.playerTriggerAttr = 'data-type-id';
    this.playerVideoId = 'data-video-id';
    this.playerInEnglish = 'data-in-english';
    this.playingVideo;
    this.cookieInterpretName = 'interpret-type';
    this.cookieInterpretLifetime = 31536000; // A year in seconds.

    // Handle multiple players on a page...
    this.containers = document.querySelectorAll(this.containerSelector);

    this.containers.forEach(container => {
      this.setupVideoPlayers(container);
      this.setupEvents(container);
      this.setDefault(container, this.getCookie());
    });

  }

  /**
   * Setup all You Tube video players.
   * */
  setupVideoPlayers(container) {
    container.querySelectorAll(this.playerSelector).forEach(element => {
      this.setupVideoPlayer(element);
    });
  }

  /**
   * Setup You Tube video player.
   * */
  setupVideoPlayer(element) {
    const type = element.getAttribute(this.playerTriggerAttr);
    const videoId = element.getAttribute(this.playerVideoId);
    const inEnglish = element.getAttribute(this.playerInEnglish);

    YouTubeIframeLoader.load(YT => {
      new YT.Player(`video-${type}-${videoId}`, {
        videoId: videoId,
        playerVars: {
          rel: 0,
          hl: inEnglish === 'true' ? 'en' : 'sv'
        },
        events: {
          onStateChange: event => {
            if (event.data == YT.PlayerState.PLAYING) {
              this.playingVideo = event.target;
            }

            else if (event.data == YT.PlayerState.PAUSED) {
              this.playingVideo = null;
            }
          }
        }
      });
    });
  }

  /**
   * Setup click events for each video type and prevent click events on disabled buttons
   */
  setupEvents(container) {
    container.addEventListener('click', event => {
      event.preventDefault();

      if(!event.target.querySelector(this.disabledButtonClass)) {
        const type = event.target.getAttribute(this.playerTriggerAttr)
        this.activateType(container, type);
      }

      if (this.playingVideo) {
        this.playingVideo.pauseVideo();
      }
    });
  }
 

  /**
   * Sets default preference for video based on cookie value.
   */
  setDefault(container, defaultVideo) {

    // Available types of given container.
    let availableTypes = [];

    container.querySelectorAll(this.playerSelector).forEach(element => {
      availableTypes.push(element.getAttribute(this.playerTriggerAttr));
    });

    if (defaultVideo && availableTypes.indexOf(defaultVideo) !== -1) {

      this.activateType(container, defaultVideo);

    } else if (availableTypes.length) {

      this.activateType(container, availableTypes[0]);

    } 
  }

  /**
   * Activate a type for given container.
   * 
   * @param container 
   * @param {String} type 
   */
  activateType(container, type) {

    // Activate the video
    container.querySelectorAll(this.playerSelector).forEach(element => {

      if (element.getAttribute(this.playerTriggerAttr) === type) {
        element.style.display = 'block';
      } else {
        element.style.display = 'none';
      }
    });

    // Activate the button
    container.querySelectorAll(this.playerTriggerButton).forEach(element => {
      if (element.getAttribute(this.playerTriggerAttr) === type) {
        element.classList.add(this.playerTriggerButtonClass + '--active');
      } else {
        element.classList.remove(this.playerTriggerButtonClass + '--active');
      }
    });

    this.setCookie(type);
  }

  /**
  * Get active interpret video type from cookie.
  */
  getCookie() {
    const result = document.cookie.match(new RegExp(this.cookieInterpretName + '="(.+?)"'), 1);
    return result ? result[1] : false
  }

  /**
   * Store active interpret video type in a cookie.
   */
  setCookie(type) {
    const value = this.cookieInterpretName + '="' + type + '"';

    document.cookie = value + ';max-age=' + this.cookieInterpretLifetime + ';path=/';
  }
} 