
export default class Analytics {

  constructor() {

  }

  dispatchEventsOnPageLoad(state, eventValue) {
    this.dispatchAnalyticsEvent(state, eventValue);
  }

  dispatchAnalyticsEvent(state, eventValue) {
    if (window && window.dataLayer && window.dataLayer.push && state && eventValue) {
      window.dataLayer.push({
        event: eventValue
      });
    }
  }
}