import axios from 'axios';
import Analytics from '../../../../Scripts/Analytics';

export default class BobbotWebGui {

  constructor() {

    this.bobbotChatSelector = '.js--bobbot-web-gui';
    this.bobbotOwnerTypeBot = 'bobbot_owner_type_bot';
    this.bobbotOwnerTypeUser = 'bobbot_owner_type_user';
    this.botStateReady = 'bot_state_ready';
    this.botStatePending = 'bot_state_pending';
    this.botStateError = 'bot_state_error';
    this.botPendingMessageClass = 'pending-message';
    this.analyticsCategory = 'Bobbot Chat';

    this._analytics = new Analytics();

    // Default state.
    this.currentState = this.botStateReady;

    // Default message id.
    this.startMessageId = '1034';

    // Flag for tracking scroll
    this._buttonsScrollReached = 0;

    // Bot container element
    const bobbotWebGui = document.querySelector(this.bobbotChatSelector);

    if (bobbotWebGui) {
     this.setup(bobbotWebGui);
    }
  }

  /**
   * Setup the bot gui.
   */
  setup(bobbotWebGui) {

    // DOM references
    this.bobbotContainer = bobbotWebGui;
    this.chatBodyContainer = this.bobbotContainer.querySelector('.bobbot__chat__body');
    this.chatHistoryContainer = this.bobbotContainer.querySelector('.bobbot__chat__body__history');
    this.buttonsContainer = this.bobbotContainer.querySelector('.bobbot__chat__footer__buttons');
    this.footerContainer = this.bobbotContainer.querySelector('.bobbot__chat__footer');
    this.resetButtonContainer = this.bobbotContainer.querySelector('.bobbot__chat__body__container__reset-container');

    this.apiPath = this.bobbotContainer.getAttribute('data-bot-api-path') || '/api/';

    // Event listeners
    this.setupEvents();

    this.createRestartButton();

    // Fetch start message
    this.fetchSettings()
      .then(this.parseSettings.bind(this))
      .then(function () { return this.startMessageId; }.bind(this))
      .then(this.fetchMessageData.bind(this));
  }

  setupEvents() {
    this.buttonsContainer.addEventListener('click', (event) => {
      // Check if the clicked element is a button
      if (event.target.classList.contains('bobbot__chat__footer__buttons__button')) {
        
        // Call the clickedMessage function with the event target as the argument
        this.clickedMessage(event);
      }
    });

    this.footerContainer.addEventListener('scroll', () => {

      // Call the scrollMessages function
      this.scrollMessages();
    });
  }

  // --------------------------------------------------------------
  // AJAX
  // --------------------------------------------------------------

  /**
   * Fetch available settings
   */
  fetchSettings() {

    return new Promise((resolve, reject) => {

      axios.get(this.apiPath + 'BotSettings')
        .then((response) => {
          resolve(response);
        })
        .catch((response) => {
          this.setBotState(this.botStateError);
          console.error('[BobbotWebGui] fetchMessageData | fail:', response);
          reject(response);
        });
    });
  }

  /**
   * Parses settings
   * @param {Object} response Object to parse
   */
  parseSettings(response) {
    // Parse settings
    if (response && response.getStartedMessageId) {
      this.startMessageId = response.getStartedMessageId
    }
  }

  /**
   * Fetch a message data object from the API.
   * @param {String} messageId The id of message to fetch from API
   */
  fetchMessageData(messageId) {
   
    return new Promise((resolve, reject) => {
      this.setBotState(this.botStatePending);
      this.pushPendingBobbotToHistory();

      axios.get(this.apiPath + 'BotMessages/' + messageId)
        .then((response) => {
          setTimeout(function () {
            this.handleBotResponse(response.data);
            resolve(response);
          }.bind(this), 1000);
        })
        .catch((response) => {
          this.setBotState(this.botStateError);
          console.error('[BobbotWebGui] fetchMessageData | fail:', response);
          reject(response);
        });
    });
  }

  /**
   * Handle message response from chat API.
   * @param {Object} response Response object
   */
  handleBotResponse(response) {

    let latest = this.getLatestChatMessage();

    if (latest.classList.contains(this.botPendingMessageClass)) {
      this.injectMessageIntoPendingBox(response.Message, latest);
    } else {
      this.pushToChatHistory(response.Message, this.bobbotOwnerTypeBot);
    }

    this.setBotState(this.botStateReady);
    this.createResponseButtons(this.parseOptions(response.Options));
    setTimeout(this.scrollToBottom.bind(this), 600);
  }

  /**
   * Clears chat history.
   */
  clearHistory() {

    // Get the children of the container
    let children = this.chatHistoryContainer.children;

    // Loop through the children
    for (let i = 0; i < children.length; i++) {
      // Get the current child
      let child = children[i];

      // Hide the child with a delay and a transition
      setTimeout(() => {
        child.style.transition = 'opacity 600ms';
        child.style.opacity = '0';

        // Remove the child after the transition ends
        child.addEventListener('transitionend', () => {
          child.remove();
        });
      }, i * 80);
    }
  }

  // --------------------------------------------------------------
  // Listeners
  // --------------------------------------------------------------

  /**
   * Click listener
   * @param {*} e Event
   */
  clickedMessage(e) {
    e.preventDefault();

    // Get the target element
    let target = e.target;

    // Push the target HTML to the chat history
    this.pushToChatHistory(target.innerHTML, this.bobbotOwnerTypeUser);

    // Set the bot state to pending
    this.setBotState(this.botStatePending);

    // Fetch the message data with a delay
    setTimeout(() => {
      // Get the message id from the target attribute
      let id = target.getAttribute('data-message-id');
      if (id) {
        // Call the fetchMessageData function with the id as the argument
        this.fetchMessageData(id);
      }
    }, 400);
  }

  /**
   * Trigger on scroll in message box.
   * Track event when reaching bottom, happens once per session.
   */
  scrollMessages(e) {

    const footer = this.footerContainer;
    const buttons = this.buttonsContainer;
    const margins = 20;
    const scrollPercent = footer.scrollTop() / ((buttons.outerHeight() + margins) - footer.innerHeight());

    if (this._buttonsScrollReached < scrollPercent) {

      if (scrollPercent >= 0.9 && this._buttonsScrollReached < 0.9) {

        // Scrolled 90%
        this._analytics.dispatchAnalyticsEvent(this.analyticsCategory, 'Scrolled messages');
      }

      this._buttonsScrollReached = scrollPercent;
    }
  }

  /**
   * Click listener
   * @param {*} e Event
   */
  clickedRestart(e) {
    e.preventDefault();

    this.clearHistory();

    this.fetchMessageData(this.startMessageId);
  }

  // --------------------------------------------------------------
  // Getters / Setters
  // --------------------------------------------------------------

  /**
   * Set current state of bot. Is used to disable buttons, display
   * "thinking" animation etc.
   * 
   * @param {String} state The state. Defined in constants.
   */
  setBotState(state) {
    this.currentState = state;

    // Add the class based on the state
    switch (state) {
      case this.botStateReady:
        this.buttonsContainer.classList.add('ready');
        this.buttonsContainer.classList.remove('pending');
        this.buttonsContainer.classList.remove('error');
        break;
      case this.botStatePending:
        this.buttonsContainer.classList.add('pending');
        this.buttonsContainer.classList.remove('ready');
        this.buttonsContainer.classList.remove('error');
        break;
      case this.botStateError:
        this.buttonsContainer.classList.add('error');
        this.buttonsContainer.classList.remove('pending');
        this.buttonsContainer.classList.remove('ready');
        break;
      default:
        this.buttonsContainer.classList.remove('ready');
        this.buttonsContainer.classList.remove('pending');
        this.buttonsContainer.classList.remove('error');
        break;
    }
  }

  /**
   * Returns object with latest chat message box.
   */
  getLatestChatMessage() {
    return this.chatHistoryContainer.lastElementChild;
  }

  // --------------------------------------------------------------
  // Helpers
  // --------------------------------------------------------------

  /**
   * Takes options from API response and returns what
   * is needed to create response buttons.
   * @param {*} options 
   */
  parseOptions(options) {
    return options
      .filter(function (option) {
        return option.ToMessageId && option.Option;
      })
      .map(function (option) {
        return {
          message: option.Option,
          id: option.ToMessageId
        }
      });
  }

  /**
   * Create new message box with pending state.
   */
  pushPendingBobbotToHistory() {

    // Create the message element
    let message = this.createChatMessageMarkup('', this.bobbotOwnerTypeBot);

    // Add the pending class
    message.classList.add(this.botPendingMessageClass);

    // Append the message to the container
    this.chatHistoryContainer.appendChild(message);

    // Show the message with a delay
    setTimeout(() => {
      // Remove the entering class
      message.classList.remove('entering');
    }, 0);
  }

  /**
   * Inject a message into an existing message box
   * 
   * @param {String} message 
   * @param $pendingBox 
   */
  injectMessageIntoPendingBox(message, pendingBox) {
    // Remove the pending class and add the ready class
    pendingBox.classList.remove(this.botPendingMessageClass);
    pendingBox.classList.add('ready');

    // Find the wrapper element
    let wrapper = pendingBox.querySelector('.bobbot__chat__body__history__message-box__wrapper');

    // Set the inner HTML to the message
    wrapper.innerHTML = message;
  }

  /**
   * Display a new message in the chat window.
   * 
   * @param {String} mesage A message to display in the chat conversation
   * @param {String} owner Id of owner, BOBBOT_OWNER_TYPE_BOT or BOBBOT_OWNER_TYPE_USER
   */
  pushToChatHistory(message, owner) {
    const messageElement = this.createChatMessageMarkup(message, owner);
    this.chatHistoryContainer.appendChild(messageElement);

    // Show the message with a delay
    setTimeout(() => {
      // Remove the entering class and add the ready class
      messageElement.classList.remove('entering');
      messageElement.classList.add('ready');

      // Scroll to the bottom with another delay
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    }, 0);
  }

  /**
   * Scroll chat window to bottom
   */
  scrollToBottom() {
    // Get the height of the history container
    let height = this.chatHistoryContainer.offsetHeight;

    // Animate the scroll top of the body container
    this.chatBodyContainer.scrollTo({
      top: height,
      behavior: 'smooth'
    });
  }

  /**
   * Remove all buttons.
   */
  clearResponseButtons() {
    // Get the children of the container
    let children = this.buttonsContainer.children;

    // Loop through the children
    for (let i = 0; i < children.length; i++) {
      // Get the current child
      let child = children[i];

      // Fade out the child with a transition
      child.style.transition = 'opacity 300ms';
      child.style.opacity = '0';

      // Remove the child after the transition ends
      child.addEventListener('transitionend', () => {
        child.remove();
      });
    }
  }

  // --------------------------------------------------------------
  // Create DOM elements
  // --------------------------------------------------------------

  /**
   * Create new reponse buttons. Remove any existing buttons.
   * @param {Array} optionsData 
   */
  createResponseButtons(optionsData) {

    // Clear the existing buttons
    this.clearResponseButtons();

    // Loop through the options data
    optionsData.forEach((option, i) => {

      // Create the button element
      let button = this.createResponseButtonMarkup(option.message, option.id);

      // Append the button to the container
      this.buttonsContainer.appendChild(button);

      // Fade in the button with a delay
      setTimeout(() => {
        button.style.display = 'inline-block';
        button.style.transition = 'opacity 500ms';
        button.style.opacity = '1';
      }, 400 + (200 * i));

    });
  }

  /**
   * Returns a object containing a new chat message markup.
   * 
   * @param {String} mesage A message to display in the chat conversation
   * @param {String} owner Id of owner, BOBBOT_OWNER_TYPE_BOT or BOBBOT_OWNER_TYPE_USER
   */
  createChatMessageMarkup(message, owner) {

    // Determine the class name based on the owner
    let className = owner === this.bobbotOwnerTypeBot ? 'bobbot__chat__body__history__message-container--bobbot' :
      owner === this.bobbotOwnerTypeUser ? 'bobbot__chat__body__history__message-container--user' :
        '';

    // Create the message element
    let messageElement = document.createElement('div');
    messageElement.classList.add('bobbot__chat__body__history__message-box');
    messageElement.innerHTML = `
      <div class="bobbot__chat__body__history__message-box__wrapper">${message}</div>
      <div class="bobbot__chat__body__history__message-box__indicator"></div>
    `;

    // Create the container element
    let containerElement = document.createElement('div');
    containerElement.classList.add('bobbot__chat__body__history__message-container', 'entering', className);
    containerElement.appendChild(messageElement);

    // Return the container element
    return containerElement;
  }

  /**
   * Returns a object containing a response button
   * @param {String} message Text content of button
   * @param {String} messageId Message ID to load from API when clicked
   */
  createResponseButtonMarkup(message, messageId) {
    // Create the button element
    let button = document.createElement('button');
    button.classList.add('button', 'bobbot__chat__footer__buttons__button', 'js--ga-click');
    button.innerHTML = message;
    button.setAttribute('ga-category', this.analyticsCategory);
    button.setAttribute('ga-action', 'Click message');
    button.setAttribute('ga-label', message);
    button.setAttribute('data-message-id', messageId);

    // Return the button element
    return button;
  }

  /**
   * Creates and appends the restart button.
   */
  createRestartButton(delay) {

    // Create the restart button element
    let restartButton = document.createElement('button');
    restartButton.classList.add('button', 'bobbot__chat__body__container__reset-container__button-restart', 'js--ga-click');
    restartButton.setAttribute('ga-category', this.analyticsCategory);
    restartButton.setAttribute('ga-action', 'Click restart');
    restartButton.innerHTML = 'Börja om';
    restartButton.addEventListener('click', (event) => {
      // Call the clickedRestart function with the event target as the argument
      this.clickedRestart(event);
    });

    // Append the button to the container
    this.resetButtonContainer.appendChild(restartButton);

    // Fade in the button with a delay
    setTimeout(() => {
      restartButton.style.transition = 'opacity 500ms';
      restartButton.style.opacity = '1';
    }, delay);
  }
}