import Emitter from 'es6-event-emitter';

export const CHANGE_STATE = 'change_state';
export const STATE_CHANGED = 'state_changed';

export default class StateHandler extends Emitter {

    constructor() {
        super();
        
        this.changeState = this.changeState.bind(this);
        this._state = {};
    }

    changeState(state) {
        const previous = { ...this._state };

        this._state = {
            ...this._state,
            ...state
        };

        this.trigger(STATE_CHANGED, this._state, previous);
    }
}