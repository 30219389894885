// Faux links to create a larger clickable area.
// Should not affect keyboard focus or screen readers.

export default function registerFauxClickAreas() {
    const fauxLinks = document.querySelectorAll('[data-faux-href]');

    if(fauxLinks) {
        fauxLinks.forEach(fauxLink => {
            let link = fauxLink.getAttribute('data-faux-href');
            fauxLink.addEventListener('click', (e) => {
                if (e.target.nodeName !== 'A') {
                    window.location.href = link;
                }
            });
        });
    }
}