export default class Polyfills {

  constructor() {

    // Array.map
    if (!Array.prototype.map) {
      Array.prototype.map = function (r) { var n, t, o; if (null == this) throw new TypeError("this is null or not defined"); var e = Object(this), i = e.length >>> 0; if ("function" != typeof r) throw new TypeError(r + " is not a function"); for (arguments.length > 1 && (n = arguments[1]), t = new Array(i), o = 0; o < i;) { var a, f; o in e && (a = e[o], f = r.call(n, a, o, e), t[o] = f), o++ } return t };
    }

  }
}