import imagesLoaded from 'imagesLoaded';
import axios from 'axios';
import masonry from 'masonry-layout';
import { CHANGE_STATE } from './StateHandler';

export default class MasonryHandler {

  constructor(stateHandler) {
    this.loadMoreButtonSelector = '.js--masonry-load-more';
    this.isLoading = false;
    this.stateHandler = stateHandler;
    this.refreshTimeout;
    this.setupGrid();
  }

  setupGrid() {

    const grid = document.querySelector('.masonry-row');

    if(!grid) {
      return;
    }

    imagesLoaded(grid, function () {

      // init Masonry after all images have loaded
      this.msnry = new masonry(grid, {
        Selector: '.masonry-row__item',
        horizontalOrder: true
      });

      window.addEventListener('resize', function () {
        this.refresh();
      }.bind(this));

      this.refresh();  
    }.bind(this));

    const button = document.querySelector(this.loadMoreButtonSelector);

    if (button) {
      button.addEventListener('click', function (event) {
        this.handleClick(event);
      }.bind(this));
    }
  }

  refresh() {

    return new Promise((resolve) => {

      if (this.refreshTimeout) {
        clearTimeout(this.refreshTimeout);
      }
      
      this.refreshTimeout = setTimeout(function () {
        this.msnry.layout();
        this.refreshTimeout = null;
        resolve();
      }.bind(this), 500);
    })
  }

  isValid(data) {

    if (typeof data !== 'string') {
      return false;
    }

    const has_load_more = data.match('load-more-container');
    const is_html_page = data.match(/<html/i);

    return is_html_page === null &&
      has_load_more === null;
  }

  handleClick(e) {
    e.preventDefault();
    const target = e.currentTarget;
    const targetId = target.getAttribute('data-masonry-load-target');
    const targetMasonry = document.querySelector('[data-masonry-id="' + targetId + '"]');
    const url = targetMasonry.getAttribute('data-load-url');

    this.fetchItems(url, targetId);
  }

  fetchItems(url, id) {

    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    axios.get(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    }).then(response => {
      this.handleResponse(response.data, id);
    }).bind(this);

  }

  handleResponse(data, id) {
    this.isLoading = false;

    if (data) {
      const targetMasonry = document.querySelector('[data-masonry-id="' + id + '"]');

      if (this.isValid(data.items.result)) {

        const content = data.items.result;
        const elements = this.htmlToElements(content);
        elements.forEach(elm => {
          this.msnry.element.appendChild(elm);
          this.msnry.appended(elm);
        });

        //set focus on first new item after page is loaded
        this.refresh().then(() => {
          elements[0].querySelector('a, button, label').focus()
        });

      } else {
        console.error('[Masonry] Malformed response');
      }

      const button = document.querySelector(this.loadMoreButtonSelector + '[data-masonry-load-target="' + id + '"]');

      if (data.loadMoreUrl) {
        targetMasonry.setAttribute('data-load-url', data.loadMoreUrl);
      } else {
        button.style.display = 'none';
      }
      this.stateHandler.trigger('masonry-load-page');

    } else {
      console.error('[Masonry] No data response');
    }
  }

  htmlToElements(html) {
    let template = document.createElement('template');
    template.innerHTML = html;

    return Array.from(template.content.childNodes).filter(node => node.nodeType === Node.ELEMENT_NODE);
  }
}